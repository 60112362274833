import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Card from '@vapor/react-material/Card';
import CardHeader from '@vapor/react-material/CardHeader';
import CardContent from '@vapor/react-material/CardContent';
import CardActions from '@vapor/react-material/CardActions';
import {Dialog, DialogActions, DialogTitle, Divider, DialogContent, Typography, DialogContentText, Box, TextField, TextareaAutosize, Button } from '@vapor/react-material';

const baseUrl = `${process.env.REACT_APP_BASEURL_API ?? 'http://localhost:5001'}/api/configurations`;

const Configurations = (
  props: {
  accessToken: string | undefined,
  setAccessToken:  React.Dispatch<React.SetStateAction<string | undefined>>,
  companyId: string | undefined, 
  setCompanyId:  React.Dispatch<React.SetStateAction<string | undefined>>}
) => {
  const [configurations, setConfigurations] = useState<[]>();
  const { accessToken, setAccessToken, setCompanyId } = props;
  console.log(accessToken)
  useEffect(() => {
    if (accessToken)
      load();
  }, [accessToken]);
  const load = () => {
    axios.get(`${baseUrl}`, {
          headers:{
            'Authorization': 'Bearer ' + accessToken
          }
        }).then(r => setConfigurations(r.data));
  };

  const selectConfiguration = (config: any) => {
    setCompanyId(config.companyId);
  };
    return (
    <>
    <h3>Manual Insert your TSID access Token</h3>
    <TextareaAutosize value={accessToken} onChange={(e) => setAccessToken(e.target.value)} />
    {configurations && configurations.map((config: any) => (
        <div key={config.id}>

    <Card>
        <CardHeader></CardHeader>
        <CardContent>
        <Typography>
          ConfigurationId = {config.id}
        </Typography>
        <Typography>
          CompanyId = {config.companyId}
        </Typography>
        </CardContent>
        <CardActions>
        <Button size="small" color="primary" onClick={() => selectConfiguration(config)}>
      Select Configuration
    </Button>
        </CardActions>
    </Card>

        </div>
    ))}
    <div>
    </div>
    </>
);
  };
  
  export default Configurations;