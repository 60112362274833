import './App.css';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Layout from "./pages/Layout";
import Home from "./pages/Home";
import Configuration from "./pages/Configuration";
import Contacts from "./pages/Contacts";
import ConversationOutlet from "./pages/ConversationOutlet";
import Conversations from "./pages/Conversations";
import Conversation from "./pages/Conversation";
import Templates from "./pages/Templates";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<Home />} />
          <Route path="configuration" element={<Configuration />} />
          <Route path="contacts" element={<Contacts />} />
          <Route path="conversations" element={<ConversationOutlet />}>
            <Route index element={<Conversations  />} />
            <Route path=":id" element={<Conversation  />} />
          </Route>
          <Route path="templates" element={<Templates />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
