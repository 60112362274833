import React, { useState, useEffect } from 'react';
import { Outlet, Link } from "react-router-dom"
import axios from 'axios';
import Card from '@vapor/react-material/Card';
import { Typography } from '@vapor/react-material';
import CardHeader from '@vapor/react-material/CardHeader';
import CardContent from '@vapor/react-material/CardContent';
import { useAuthorizationHeader } from './Layout';

const baseUrl = `${process.env.REACT_APP_BASEURL_API ?? 'http://localhost:5009'}/api/conversations`;

const Conversations = () => {

  const headers = useAuthorizationHeader();
  const [conversations, setConversations] = useState<[]>();

  useEffect(() => {
    load();
  }, []);
  const load = () => {
    axios.get(`${baseUrl}`, {
          headers
        }).then(r => setConversations(r.data));
  };

    return (
    <>
    {conversations && conversations.map((conversation: any) => (
        <div key={conversation.id}>

<Link to={`/conversations/${conversation.id}`}>
    <Card>
        <CardHeader title={`${conversation.channel.channel} ${conversation.contact.name ?? ''} ${conversation.contact.surname ?? ''}  ${conversation.contact.businessName ?? ''} ${conversation.contactInfo}` }>
        </CardHeader>
        <CardContent>
        <Typography>
{conversation.lastMessageTimestamp}
        </Typography>
        </CardContent>
    </Card>
    </Link>
        </div>
    ))}
    </>
);
  };
  
  export default Conversations;