import { Outlet, Link, useNavigate, useOutletContext } from "react-router-dom";
import React,  { useState } from "react";
import { VaporAppBar, VaporUIShellNav, VaporAppLauncher } from '@vapor/react-uishell';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCog, faUsers, faComments, faFile } from '@fortawesome/free-solid-svg-icons'
import { Typography, IconButton, Grid, ListItemButton, ListItemIcon, List, ListItemText, Button, TextareaAutosize } from '@vapor/react-material';
import VaporPage from "@vapor/react-custom/VaporPage";
import Configurations from "./Configurations";
import VaporThemeProvider from "@vapor/react-material/styles";

const Layout = () => {
    const navigate = useNavigate()
    const [accessToken, setAccessToken] = useState<string>();
    const [companyId, setCompanyId] = useState<string>();
    const [drawerOpen, setDrawerOpen] = React.useState(false);
    const isLarge = true;
    const [openApp, setApp] = React.useState(false);
    React.useEffect(() => {
        if (isLarge && !drawerOpen) {
            setDrawerOpen(true);
        }
        if (!isLarge && drawerOpen) {
            setDrawerOpen(false);
        }
    }, [isLarge]);
    const toggleDrawer = () => {
        setDrawerOpen(!drawerOpen);
    };
    return (
        <>
            <VaporThemeProvider>

                <VaporUIShellNav drawerOpen={drawerOpen} setDrawerOpen={setDrawerOpen} 
                renderToolbar={<VaporAppBar isDrawerOpen={drawerOpen} toggleDrawer={toggleDrawer} rightContent={<>
                    <IconButton size="large" onClick={() => setApp(!openApp)}>
                        <Grid color="white" />
                    </IconButton>
                </>
            }
        
            />} 
            menuLevels={{
                children: [{
                  label: "Contacts",
                  icon: <FontAwesomeIcon icon={faUsers} />,
                  onClickFunction: () => {
                    navigate('/contacts');
                  }
                },
                {
                    label: "Templates",
                    icon: <FontAwesomeIcon icon={faFile} />,
                    onClickFunction: () => {
                        navigate('/templates');
                    }
                  },
                  {
                    label: "Conversations",
                    icon: <FontAwesomeIcon icon={faComments} />,
                    onClickFunction: () => {
                        navigate('/conversations');
                    }
                  },
                  {
                    label: "Configuration",
                    icon: <FontAwesomeIcon icon={faCog} />,
                    onClickFunction: () => {
                        navigate('/configuration');
                    }
                  }]
              }}
>
                    <VaporAppLauncher open={openApp} setApp={setApp}></VaporAppLauncher>

                    <VaporPage
                        title="Unified Omni Channel"
                    > <VaporPage.Section>
                        {
(accessToken && companyId) ? <Outlet context={{accessToken: accessToken, companyId }} />
: <Configurations accessToken={accessToken} setAccessToken={setAccessToken} companyId={companyId} setCompanyId={setCompanyId} />
                        }
                        </VaporPage.Section>

                    </VaporPage>
                </VaporUIShellNav>;

                <VaporAppBar
                    isDrawerOpen={false}
                />

            </VaporThemeProvider>
        </>
    )
};

export function useAuthorizationHeader() {
    const outletContext = useOutletContext<{ accessToken: string,
        companyId: string
     }>();
     return {
        'Authorization': 'Bearer ' + outletContext.accessToken,
        'X-Company-Id': outletContext.companyId
      }
}

export default Layout;