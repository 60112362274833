import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Card from '@vapor/react-material/Card';
import CardHeader from '@vapor/react-material/CardHeader';
import CardContent from '@vapor/react-material/CardContent';
import CardActions from '@vapor/react-material/CardActions';
import {Dialog, DialogActions, DialogTitle, Divider, DialogContent, Typography, DialogContentText, Box, TextField } from '@vapor/react-material';
import Button from '@vapor/react-material/Button';
import logo from './logo.svg';
import { useAuthorizationHeader } from './Layout';


const baseUrl = `${process.env.REACT_APP_BASEURL_API ?? 'http://localhost:5006'}/api/whatsapp/configuration`;

const Configuration = () => {
  const headers = useAuthorizationHeader();
  const [configurations, setConfigurations] = useState<[]>();

  useEffect(() => {
    load();
  }, []);
  const load = () => {
    axios.get(`${baseUrl}`, {
          headers
        }).then(r => setConfigurations(r.data));
  };

  const deleteConfiguration = (id: string) => {
axios.delete(`${baseUrl}/${id}`, {
        headers
      }).then(r => load());
  };

  const launchWhatsAppSignup = () => {
    (window as any).FB.login((response: any) => {
      if (response.authResponse) {

        console.log(response);
        const code = response.authResponse.code;
        
        const cb = `${baseUrl}/integrated`;
        axios.put(cb, JSON.stringify(code), {
          headers:{
            ...headers,
            'Content-Type': 'application/json'
          }
        }).then(res => {
            load();
        });
          //Use this token to call the debug_token API and get the shared WABA's ID
      } else {
        console.log('User cancelled login or did not fully authorize.');
      }
    }, {
      config_id: '893544509166540', // configuration ID obtained in the previous step goes here
      response_type: 'code',     // must be set to 'code' for System User access token
      override_default_response_type: true,
      extras: {
        setup: {
        }
      }
    });
  }


  const [open, setOpen] = React.useState(false);
  const [pin, setPin] = React.useState('');
  const handleDialog = () => {
      setOpen(!open);
  };
  const registerPhoneNumber = (configId: string, phoneNumberId: string) => {

axios.post(`${baseUrl}/${configId}/register/${phoneNumberId}`, 
    JSON.stringify(pin),
{
  headers:{
    ...headers,
    'Content-Type': 'application/json'
  }
  }).then(r => {
    setOpen(false);
    load();
}
    );
  };

    return (
    <>
    <h1>Configuration</h1>
    {configurations && configurations.map((config: any) => (
        <div key={config.id}>

    <Card>
        <CardHeader></CardHeader>
        <CardContent>
        <Typography>
{config.id}
        </Typography>
        <Typography>
{config.whatsAppBusinessId}
        </Typography>
        <Typography>
{config.phoneNumbers[0].displayPhoneNumber}
        {!config.phoneNumbers[0].registered ? (
            <Button size="small" color="primary" onClick={() => { setPin(''); setOpen(true);}}>
                Register
            </Button>
        ) : (
            <Typography>
                Registered
            </Typography>
        )}
        </Typography>
        </CardContent>
        <CardActions>
        <Button size="small" color="error" onClick={() => deleteConfiguration(config.id)}>
      Delete
    </Button>
        </CardActions>


  <Dialog open={open} onClose={handleDialog} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
        <DialogTitle>
          Register
        </DialogTitle>
        <Divider variant="fullWidth" />
        <DialogContent>
          <Box autoComplete="off" component="form" noValidate sx={{
          "& > :not(style)": {
            mt: 2,
            mb: 4,
            ml: 0,
            width: 250
          }
        }}>
            <TextField id="outlined-basic" label="PIN" variant="outlined" value={pin} onChange={(e) => setPin(e.currentTarget.value)} />
          </Box>
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" onClick={() => setOpen(false)}>Cancel</Button>
          <Button variant="contained" sx={{
          mr: 1
        }} onClick={() => registerPhoneNumber(config.id, config.phoneNumbers[0].id)}>
            Register
          </Button>
        </DialogActions>
      </Dialog>
    </Card>

        </div>
    ))}
    <div>

    <Button
    variant="contained"
    onClick={launchWhatsAppSignup}
  >
    Add
  </Button>
    </div>
    </>
);
  };
  
  export default Configuration;