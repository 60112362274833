import React, { useState, useEffect } from 'react';
import { Outlet, Link } from "react-router-dom"
import { useOutletContext } from 'react-router-dom';
const ConversationOutlet = () => {
    const context = useOutletContext();
    return (
    <>
    <h1>Conversations</h1>
    <Outlet context={context} />
    </>
);
  };
  
  export default ConversationOutlet;