import React, { useState, useEffect } from 'react';
import { useParams } from "react-router-dom"
import axios from 'axios';
import { Card, Typography, CardHeader, CardContent } from '@vapor/react-material';
import '../scss/Conversation.scss';
import { useAuthorizationHeader } from './Layout';

const baseUrl = `${process.env.REACT_APP_BASEURL_API ?? 'http://localhost:5009'}/api/conversations`;
const Conversation = () => {
    
  const headers = useAuthorizationHeader();
    const [messages, setMessages] = useState<[]>();
    const params = useParams()

    useEffect(() => {
        load();
    }, []);
    const load = () => {
        axios.get(`${baseUrl}/${params.id}/messages`, {
            headers
        }).then(r => setMessages(r.data));
    };

    return (
        <div className="conversation">
            {messages && messages.map((message: any) => (
<>
                <Card key={message.id} className={'message ' + message.direction}>
                    <CardContent>
                        <Typography>
                            {message.text}
                        </Typography>
                    </CardContent>
                </Card>
                <br />
</>

            ))}
        </div>
    );
};

export default Conversation;