import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Card from '@vapor/react-material/Card';
import CardHeader from '@vapor/react-material/CardHeader';
import CardContent from '@vapor/react-material/CardContent';
import CardActions from '@vapor/react-material/CardActions';
import {Dialog, DialogActions, DialogTitle, Divider, DialogContent, Typography, DialogContentText, Box, TextField } from '@vapor/react-material';
import Button from '@vapor/react-material/Button';
import logo from './logo.svg';
import { useAuthorizationHeader } from './Layout';

const baseUrl = `${process.env.REACT_APP_BASEURL_API ?? 'http://localhost:5008'}/api/contacts`;

const Templates = () => {

  const headers = useAuthorizationHeader();
  const [contacts, setContacts] = useState<[]>();

  useEffect(() => {
    load();
  }, []);
  const load = () => {
    axios.get(`${baseUrl}`, {
          headers
        }).then(r => setContacts(r.data));
  };
  const deleteTemplate = (id: string) => {
axios.delete(`${baseUrl}/${id}`, {
        headers
      }).then(r => load());
  };

    return (
    <>
    <h1>Contacts</h1>
    {contacts && contacts.map((contact: any) => (
        <div key={contact.id}>

    <Card>
        <CardHeader title={`${contact.name ?? ''} ${contact.surname ?? ''} ${contact.businessName ?? ''}` }>
        </CardHeader>
        <CardContent>
        <Typography>
{contact.id}
        </Typography>
          {contact.phones && contact.phones.map((phone: any) => (
<Typography>
  {phone}
</Typography>
          ))}
          {contact.emails && contact.emails.map((email: any) => (
<Typography>
  {email}
</Typography>
          ))}
        </CardContent>
        <CardActions>
        <Button size="small" color="error" onClick={() => deleteTemplate(contact.id)}>
      Delete
    </Button>
        </CardActions>
    </Card>

        </div>
    ))}
    </>
);
  };
  
  export default Templates;