import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Card from '@vapor/react-material/Card';
import CardHeader from '@vapor/react-material/CardHeader';
import CardContent from '@vapor/react-material/CardContent';
import CardActions from '@vapor/react-material/CardActions';
import {Dialog, DialogActions, DialogTitle, Divider, DialogContent, Typography, DialogContentText, Box, TextField } from '@vapor/react-material';
import Button from '@vapor/react-material/Button';
import logo from './logo.svg';
import { useAuthorizationHeader } from './Layout';

const baseUrl = `${process.env.REACT_APP_BASEURL_API ?? 'http://localhost:5002'}/api/templates`;

const Templates = () => {

  const headers = useAuthorizationHeader();
  const [templates, setTemplates] = useState<[]>();

  useEffect(() => {
    load();
  }, []);
  const load = () => {
    axios.get(`${baseUrl}`, {
          headers
        }).then(r => setTemplates(r.data));
  };
  const deleteTemplate = (id: string) => {
axios.delete(`${baseUrl}/${id}`, {
        headers
      }).then(r => load());
  };

    return (
    <>
    <h1>Templates</h1>
    {templates && templates.map((template: any) => (
        <div key={template.id}>

    <Card>
        <CardHeader title={template.name}>
        </CardHeader>
        <CardContent>
        <Typography>
{template.id}
        </Typography>
        <Typography>
{template.body}
        </Typography>
        </CardContent>
        <CardActions>
        <Button size="small" color="error" onClick={() => deleteTemplate(template.id)}>
      Delete
    </Button>
        </CardActions>
    </Card>

        </div>
    ))}
    </>
);
  };
  
  export default Templates;